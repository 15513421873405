import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import login_left from '../../../assets/images/login_left.png';
import login_logo from '../../../assets/images/login_logo.png';

import { userActions } from '../../../_actions'



function Login() {
    useEffect(() => {
        localStorage.removeItem('access_token')
    }, []);
    const [login, setLogin] = useState({
        email: '',
        password: '',
        hold: ''
    });


    const [submitted, setSubmitted] = useState(false);
    const [emailValid, setEmailValid] = useState(true);
    const [disableInputIsChecked, setDisableInputIsChecked] = React.useState(false);
    const { email, password } = login;
    const loggingIn = useSelector(state => state.authentication.loggingIn);
    const dispatch = useDispatch();
    const location = useLocation();

    function handleChange(e) {
        if (e.target.name == 'email') {
            var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if (!filter.test(e.target.value)) {
                setEmailValid(false)
            } else {
                setEmailValid(true)
            }
        } // email validation
        const { name, value } = e.target;
        setLogin(login => ({ ...login, [name]: value }));
    }


    function handleCheckboxClick(event) {
        if (!disableInputIsChecked) { // this click was to check the box
            login.hold = 1;
        }
        else {
            login.hold = '';
        }
        setDisableInputIsChecked(prevValue => !prevValue); // invert value
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if (email && password && emailValid && disableInputIsChecked) {
            // get return url from location state or default to home page
            const { from } = location.state || { from: { pathname: "/dashboard" } };
            dispatch(userActions.login(email, password, from));
        }
        setTimeout(() => {
            setSubmitted(false)
        }, 2000)
    }

    //eyuerhthhhtuhtrhdhhtrhturhtuuiuirthuhhthrurugyuyu
    return (
        <div className="login_container flt">
            <div className="login_left">
                <img src={login_left} alt="white-rice" />
            </div>
            <div className="login_right">
                <div className="login_logo flt">
                    <img src={login_logo} alt="logo" />
                </div>

                <div className="login_txt flt">LOGIN</div>
                <form className="login_form flt" onSubmit={handleSubmit}>
                    <div className="form-group flt">
                        <input type="text" name="email" value={login.email} onChange={handleChange} placeholder="企業様ID" className={(submitted && !login.email ? ' is-invalid' : '')} />
                        {submitted && !login.email &&
                            <div className="invalid-feedback">Company ID is required</div>
                        }
                        {login.email && !emailValid &&
                            <div className="invalid-feedback">Please enter valid email ID</div>
                        }
                    </div>
                    <div className="form-group flt">
                        <input type="password" name="password" value={login.password} onChange={handleChange} placeholder="パスワード" className={(submitted && !login.password ? ' is-invalid' : '')} />
                        {submitted && !login.password &&
                            <div className="invalid-feedback">Password is required</div>

                        }
                    </div>
                    <div className="form-group flt">
                        <input type="checkbox" id="hold" name="hold" value="1" onChange={handleCheckboxClick} className={(submitted && !login.hold ? ' is-invalid' : '')} />
                        <label htmlFor="hold">ログイン情報を保持</label>
                        {submitted && !login.hold &&
                            <div className="invalid-feedback">You must check this to login</div>
                        }
                    </div>
                    <div className="login_btn flt">
                        {/* <Link to="/dashboard">Login</Link> */}
                        <button>{submitted && <span className="spinner-border spinner-border-sm mr-1"></span>}Login</button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default Login
