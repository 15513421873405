import { authHeader } from '../_helpers';
const axios = require('axios').default;
export const salesService = {
    sales_get
}
async function sales_get() {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}merchant/signage-current`,
        headers: authHeader()
    };
     return await axios(
        requestOptions
    ).then(handleResponse);
}

function handleResponse(response) {
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }

        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }

    return response.data;

}