import { authHeader } from '../_helpers';
const axios = require('axios').default;
export const settingService = {
    signage_get,
    getSingle,
    signage_update
}
async function signage_get() {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}merchant/signage-get/`,
        headers: authHeader()
    };
     return await axios(
        requestOptions
    ).then(handleResponse);
}

async function getSingle(id) {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}merchant/signage-get/${id}`,
        headers: authHeader()
    };
     return await axios(
        requestOptions
    ).then(handleResponse);
}

async function signage_update(selectSignage,checked) {
    console.log(selectSignage)
    var bodyFormData = new FormData();
    bodyFormData.append("floor",selectSignage.floor)
    bodyFormData.append("signagename",selectSignage.signagename)
    bodyFormData.append("ratio",selectSignage.ratio)
    if(checked==true){
    bodyFormData.append("ads_setting",1)
    }else{
        bodyFormData.append("ads_setting",0) 
    }
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL}merchant/signage-update/`+selectSignage._id,
        headers: authHeader(),
        data: bodyFormData
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
    
}

function handleResponse(response) {
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }

        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }

    return response.data;

}