import { authHeader } from '../_helpers';
const axios = require('axios').default;
export const invoiceindividualService = {
    invoice_get
}
async function invoice_get(id) {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}merchant/sales-invoice/${id}`,
        headers: authHeader()
    };
     return await axios(
        requestOptions
    ).then(handleResponse);
}

function handleResponse(response) {
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }

        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }

    return response.data;

}