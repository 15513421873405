import { authHeader } from '../_helpers';
const axios = require('axios').default;
export const profileService = {
    getProfile,
    updateProfile
}

async function getProfile() {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}merchant/my-profile`,
        headers: authHeader()
    };
     return await axios(
        requestOptions
    ).then(handleResponse);
}


async function updateProfile (data, profile) {
    var bodyFormData = new FormData();
    bodyFormData.append("profile",profile)
    bodyFormData.append("firstName",data.firstName)
    bodyFormData.append("company_name",data.company_name)
    bodyFormData.append("telephone_number",data.telephone_number)
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL}merchant/update-profile`,
        headers: authHeader(),
        data: bodyFormData
    };

    return await axios(
        requestOptions
    ).then(handleResponse);
}


function handleResponse(response) {
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }

        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }

    return response.data;

}