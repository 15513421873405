import React from 'react'
import { Link,useLocation} from "react-router-dom";
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import sidebar_logo from '../../../assets/images/sidebar_logo.png';
import menu_dashboard from '../../../assets/images/menu_dashboard.png';
import menu_profile from '../../../assets/images/menu_profile.png';
import menu_setting from '../../../assets/images/menu_setting.png';
import menu_contact from '../../../assets/images/menu_contact.png';
import menu_privacy from '../../../assets/images/menu_privacy.png';
import menu_terms from '../../../assets/images/menu_terms.png';
import menu_sales from '../../../assets/images/menu_sales.png';

import version from '../../../assets/images/version.png'

function Sidebar() {

    var location = useLocation().pathname;
   
    return (
        <div className="sidebar">
                <div className="sidebar_logo flt">
                    <img src={sidebar_logo} alt="logo" />
                </div>
                <OverlayScrollbarsComponent options={{ scrollbars: { autoHide: 'scroll' } }} className="sidebar_nav flt">
                    <ul>
                        <li>
                            <Link to="/dashboard" className={location==='/dashboard' ? "active" : ''}><span className="nav_icon"><img src={menu_dashboard} alt="menu icon" /></span><span className="nav_txt">DASHBOARD</span></Link>
                        </li>
                        <li>
                            <Link to="/profile"  className={location==='/profile' || location==='/password-security'? "active" : ''}><span className="nav_icon"><img src={menu_profile} alt="menu icon" /></span><span className="nav_txt">PROFILE</span></Link>
                        </li>
                        <li>
                            <Link to="/setting" className={location==='/setting' ? "active" : ''}><span className="nav_icon"><img src={menu_setting} alt="menu icon" /></span><span className="nav_txt">SETTING</span></Link>
                        </li>
                        <li>
                            <Link to="/contact" className={location==='/contact' ? "active" : ''}><span className="nav_icon"><img src={menu_contact} alt="menu icon" /></span><span className="nav_txt">CONTACT</span></Link>
                        </li>
                        <li>
                            <Link to="/privacy-policy" className={location==='/privacy-policy' ? "active" : ''}><span className="nav_icon"><img src={menu_privacy} alt="menu icon" /></span><span className="nav_txt">PRIVACY POLICY</span></Link>
                        </li>
                        <li>
                            <Link to="/terms-conditions" className={location==='/terms-conditions' ? "active" : ''}><span className="nav_icon"><img src={menu_terms} alt="menu icon" /></span><span className="nav_txt">T&C</span></Link>
                        </li>
                        <li>
                            <Link to="/sales" className={(location==='/sales' || location==='/invoice' || location==='/invoice-individual/:invoice_id') ? "active" : ''}><span className="nav_icon"><img src={menu_sales} alt="menu icon" /></span><span className="nav_txt">SALES</span></Link>
                        </li>
                    </ul>
                    <div className="version">
                        <img src={version} alt="version" /><br />
                        V.1.0.0
                    </div>
                </OverlayScrollbarsComponent>
            </div>
    )
}

export default Sidebar
