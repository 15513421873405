const message = {
    title: "Are you sure?",
    "dashboard": {
        add: "Advertisement will be added!",
        update: "Advertisement will be updated!",
        delete: "Signage will be deleted!",
    },
    "dashboard": {
        add: "Advertisement will be added!",
        update: "Advertisement will be updated!",
        delete: "Signage will be deleted!",
    },
    "profile": {
        update: "Profile will be updated!"
    },
    "password": {
        update: "Password will be updated!"
    },
    "setting": {
        update: "Signage will be updated!"
    },
    "contact": {
        message: "Message will be sent!"
    }
}
export default message