import { authHeader } from '../_helpers';
const axios = require('axios').default;
export const dashboard = {
    signage_get,
    ads_get,
    getSingle,
    signage_content,
    updateOrder,
    uploader,
    signage_edit,
    drag,
    delete: _delete
}

async function signage_get() {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}merchant/signage-get`,
        headers: authHeader()
    };
     return await axios(
        requestOptions
    ).then(handleResponse);
}

async function ads_get(signageId) {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}merchant/advertisement-list?signageId=`+signageId,
        headers: authHeader()
    };

    return await axios(
        requestOptions
    ).then(handleResponse);
}

async function getSingle(id) {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}merchant/signage-get/${id}`,
        headers: authHeader()
    };
     return await axios(
        requestOptions
    ).then(handleResponse);
}

async function signage_content(signageId) {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}merchant/signage-content?signageId=`+signageId,
        headers: authHeader()
    };

    return await axios(
        requestOptions
    ).then(handleResponse);
}


async function updateOrder(item,signageId) {
    
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL}merchant/signage-content/order-update`,
        headers: authHeader(),
        data: {
            item: item,
            signageId:signageId
        }
    };

    return await axios(
        requestOptions
    ).then(handleResponse);
}


async function signage_edit (uploader, upload_file) {
    console.log(uploader)
    var bodyFormData = new FormData();
    // if(upload_file){
    bodyFormData.append("upload_file",upload_file)
    // }
    bodyFormData.append("title",uploader.title)
    bodyFormData.append("representation_time",uploader.representation_time)
    bodyFormData.append("loop_playback",uploader.loop_playback)
    bodyFormData.append("signageId",uploader.signageId)
    bodyFormData.append("publication_date_and_fromtime",uploader.publication_date_and_fromtime)
    bodyFormData.append("publication_date_and_totime",uploader.publication_date_and_totime)
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL}merchant/signage-content/`+uploader._id,
        headers: authHeader(),
        data: bodyFormData
    };

    return await axios(
        requestOptions
    ).then(handleResponse);
}


async function uploader(uploader,upload_file) {
    var bodyFormData = new FormData();
    bodyFormData.append("title",uploader.title)
    bodyFormData.append("representation_time",uploader.representation_time)
    bodyFormData.append("loop_playback",uploader.loop_playback)
    bodyFormData.append("publication_date_and_fromtime",uploader.publication_date_and_fromtime)
    bodyFormData.append("publication_date_and_totime",uploader.publication_date_and_totime)
    bodyFormData.append("signageId",uploader.signageId)
    bodyFormData.append("upload_file",upload_file)

    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL}merchant/signage-content`,
        headers: authHeader(),
        data: bodyFormData
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}



async function _delete(id) {
    console.log(id);
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_BASE_URL}merchant/signage-content/${id}`,
        headers: authHeader()
    };

    return await axios(
        requestOptions
    ).then(handleResponse);

}


async function drag(id,signageId) {
    console.log(id.draggableId);
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL}merchant/signage-content/add-adds/${id.draggableId}`,
        headers: authHeader(),
        data: {
            signageId:signageId
        }
    };
     return await axios(
        requestOptions
    ).then(handleResponse);
}

function handleResponse(response) {
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }

        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }

    return response.data;

}