import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import { contactService } from '../../../_services';
import { alertActions } from '../../../_actions/alert.actions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import message from '../../_common/Message/message';

function Contact() {
    const [contact, setContact] = useState({
        name: '',
        furigana: '',
        email: '',
        phone_number: '',
        genre: '',
        content: ''
    })

    const [emailValid, setEmailValid] = useState(true);
    const [numberValid, setNumberValid] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const dispatch = useDispatch();

    function handleChange(e) {
        if (e.target.name == 'email') {
            var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if (!filter.test(e.target.value)) {
                setEmailValid(false)
            } else {
                setEmailValid(true)
            }
        } // email validation

        if (e.target.name == 'phone_number') {
            var filter = /^[0-9]*$/;
            if (!filter.test(e.target.value)) {
                setNumberValid(false)
            } else {
                setNumberValid(true)
            }
        } // number validation


        const { name, value } = e.target;
        setContact(contact => ({ ...contact, [name]: value }));

    }

    function handleSubmit(e) {
        console.log(contact)
        e.preventDefault();
        setSubmitted(true);
        if (contact.phone_number && !numberValid) {
            return false
        }
        if (!emailValid) {
            return false
        }
        if (contact.name && contact.furigana && contact.email && contact.genre && contact.content) {
            confirmAlert({
                title: message.title,
                message: message.contact.message,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => contactService.contact(contact)
                            .then((data) => {
                                if (data.status.code == 0) {
                                    setSubmitted(false);
                                    setContact({
                                        name: '',
                                        furigana: '',
                                        email: '',
                                        phone_number: '',
                                        genre: '',
                                        content: ''
                                    });
                                    dispatch(alertActions.success(data.status.message));
                                    setTimeout(
                                        () => (dispatch(alertActions.clear())),
                                        2000
                                    );
                                }
                            })
                    },
                    {
                        label: 'No',
                        onClick: () => null
                    }
                ]
            });



        }
    }

    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container">
                <PageHead title="CONTACT" />
                <div className="contact_main flt">
                    <div className="contact_head flt">サイネージ設定についてのお困りの場合は下記問い合わせフォーム、<br /> またはお電話にてよろしくお願いいたします</div>
                    <div className="contact_flex flt">
                        <div className="contact_box">
                            <div className="contact_tag flt">お電話でのお問い合わせ</div>
                            <div className="contact_desc flt">神戸制作オフィス<br /> 000-0000-000</div>
                        </div>
                        <div className="contact_box">
                            <div className="contact_tag flt">メールでのお問い合わせ</div>
                            <div className="contact_desc flt">mail@mail.jp</div>
                        </div>
                    </div>
                    <form className="contact_form flt" onSubmit={handleSubmit}>
                        <div className="form-group flt">
                            <label>お名前<span>必須</span></label>
                            <input type="text" name="name" value={contact.name} onChange={handleChange} className={'small_ip' + (submitted && !contact.name ? ' is-invalid' : '')} />
                            {submitted && !contact.name &&
                                <div className="invalid-feedback">Name is required</div>
                            }
                        </div>
                        <div className="form-group flt">
                            <label>フリガナ<span>必須</span></label>
                            <input type="text" name="furigana" value={contact.furigana} onChange={handleChange} className={'small_ip' + (submitted && !contact.furigana ? ' is-invalid' : '')} />
                            {submitted && !contact.furigana &&
                                <div className="invalid-feedback">Furigana is required</div>
                            }
                        </div>
                        <div className="form-group flt">
                            <label>メールアドレス<span>必須</span></label>
                            <input type="text" name="email" value={contact.email} onChange={handleChange} className={'medium_ip' + (submitted && !contact.email ? ' is-invalid' : '')} />
                            {submitted && !contact.email &&
                                <div className="invalid-feedback">Email Id is required</div>
                            }
                            {!emailValid &&
                                <div className="invalid-feedback">Please enter valid email ID</div>
                            }
                        </div>
                        <div className="form-group flt">
                            <label>お電話番号(任意)</label>
                            <input type="text" name="phone_number" value={contact.phone_number} onChange={handleChange} className='medium_ip' />
                            {!numberValid &&
                                <div className="invalid-feedback">Please enter valid phone number</div>
                            }
                        </div>
                        <div className="form-group flt">
                            <label>お問い合わせジャンル<span>必須</span></label>
                            <input type="text" name="genre" value={contact.genre} onChange={handleChange} className={'medium_ip' + (submitted && !contact.genre ? ' is-invalid' : '')} />
                            {submitted && !contact.genre &&
                                <div className="invalid-feedback">Inquiry genre is required</div>
                            }
                        </div>
                        <div className="form-group flt">
                            <label>お問い合わせ内容<span>必須</span></label>
                            <textarea name="content" value={contact.content} onChange={handleChange} className={(submitted && !contact.content ? ' is-invalid' : '')}></textarea>
                            {submitted && !contact.content &&
                                <div className="invalid-feedback">Inquiry content is required</div>
                            }
                        </div>
                        <div className="contact_btn flt">
                            <button>SEND</button>
                        </div>
                    </form>

                </div>

            </div>
        </div>
    )
}

export default Contact
