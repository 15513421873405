export function authHeader() {
    // return authorization header with jwt token
    let access_token = JSON.parse(localStorage.getItem('access_token'));
    
    if (access_token) {
        return { 'Authorization': 'Bearer ' + access_token,
                 'Content-Type': 'application/json; charset=utf-8'
        };
    } else {
        return { 'Content-Type': 'application/json; charset=utf-8' };
    }
}