import { authHeader } from '../_helpers';
import axios from 'axios'
export const contactService = {
    contact
};

async function contact(contact) {
    console.log(contact)
    var bodyFormData = new FormData();
    bodyFormData = {
        name: contact.name,
        furigana: contact.furigana,
        email: contact.email,
        phone: contact.phone_number,
        genre: contact.genre,
        content: contact.content
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        url: `${process.env.REACT_APP_BASE_URL}merchant/contact`,
        data: bodyFormData
    };

    return await axios(
        requestOptions
    ).then(handleResponse);

    // return fetch(`${process.env.REACT_APP_BASE_URL}merchant/contact`, requestOptions)
    //     // .then(handleResponse)
    //     .then(user => {
    //         // store user details and jwt token in local storage to keep user logged in between page refreshes
    //         return user;
    //     });
}



// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 // auto logout if 401 response returned from api
//                 // logout();
//                 //window.location.reload(true);
//             }

//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }

//         return data;
//     });
// }

function handleResponse(response) {
    console.log(response)
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }
        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }
    return response.data;
}