import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import { Link } from 'react-router-dom';
import { salesService } from '../../../_services';
import print from '../../../assets/images/print.png'
import star from '../../../assets/images/star.png'
import save from '../../../assets/images/save.png'
import sort from '../../../assets/images/sort.png'
import sales_price from '../../../assets/images/sales_price.png'


function Sales() {

    const [data, setData] = useState([]);
    const [current, setcurrent] = useState(1);

    useEffect(() => {
        salesService.sales_get()
            .then((data) => setData(data))
    }, []);

    const changeCurrentPage = (page) => {
        setcurrent(page)
    }

    const viewList = [
        { value: 'Default View', label: 'Default View' },
        { value: 'view 1', label: 'view 1' },
        { value: 'view 2', label: 'view 2' },
        { value: 'view 3', label: 'view 3' },
        { value: 'view 4', label: 'view 4' }
    ]

    const sortList = [
        { value: 'Latest', label: 'Latest' },
        { value: 'Old', label: 'Old' }
    ]

    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container">
                <PageHead title="SALES" />
                <div className="sales_main flt">
                    <div className="sales_option flt">
                        <img src={print} alt="white-rice" />
                        <img src={save} alt="white-rice" />
                        <div className="filler"></div>
                        <Link to="/invoice" className="invoice_btn"><i className="fas fa-file-alt"></i> Invoice</Link>
                        <div className="select_group">
                            <img src={star} alt="white-rice" />
                            <Select options={viewList}
                                defaultValue={{ label: 'Default View', value: 'Default View' }}
                            />
                        </div>
                        <div className="select_group">
                            <img src={sort} alt="white-rice" />
                            <Select options={sortList}
                                defaultValue={{ label: 'Latest', value: 'Latest' }}
                            />
                        </div>
                    </div>
                    <div className="sales_table flt">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th width="60px">Sl. No</th>
                                    <th>Reference</th>
                                    {/* <td>Brand</td> */}
                                    <th>Description</th>
                                    <th>Date of Add</th>
                                    <th>Date of Expiry</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.data && data.data.sales != null ? data.data.sales.map((list, index) =>
                                    <tr key={list._id}>
                                        <td>{index + 1}.</td>
                                        <td>{list.referenceId}</td>
                                        <td>{list.ads_info}</td>
                                        {/* <td>吉野家　三宮店<br /><span>テキストテキスト</span></td> */}
                                        <td>{list.added_date}</td>
                                        <td>{list.expiry_date}</td>
                                        <td>{list.amount}</td>
                                    </tr>) : null}
                            </tbody>
                        </table>
                    </div>
                    <div className="sales_total flt">
                        <div className="total_box">
                            <div className="price_txt">
                                <div className="price_tag flt">TOTAL PRICE :</div>
                                <div className="price_rate flt">¥ {data.data && data.data.amount}</div>
                            </div>
                            <div className="filler"></div>
                            <div className="price_icon">
                                <img src={sales_price} alt="white-rice" />
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default Sales
