import React, { useEffect, useState } from 'react'
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import { appsetting } from '../../../_services/appsetting';
function TermsConditions() {
    const [data, setData] = useState(null);

    useEffect(() => {
        async function getdata() {
            await appsetting.getcontent("tc")
                .then((dat) => {
                    setData(dat)
                })
        }
        getdata();
    }, [])

    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container">
                <PageHead title="TERMS & CONDITION " />
                <div className="terms_main flt">
                    {data && data.data.content && <div className="preview_cont flt" dangerouslySetInnerHTML={{ __html: data && data.data.content }}>
                    </div>}
                </div>
            </div>
        </div >
    )
}

export default TermsConditions
