import React, { useState, useEffect } from 'react'
import Switch from "react-switch";
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { settingService } from '../../../_services';
import { alertActions } from '../../../_actions/alert.actions';
import { useDispatch } from 'react-redux';
import message from '../../_common/Message/message';

function Setting() {
    const dispatch = useDispatch();
    const [data, getSignage] = useState([]);
    const [selectSignage, setSignage] = useState({ signagename: '', _id: '', floor: '', ratio: '', ads_setting: '' });
    const [checked, setChecked] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };

    const changeHandler = (e) => {
        console.log(selectSignage);
        const { name, value } = e.target;
        setSignage(selectSignage => ({ ...selectSignage, [name]: value }))

        if (e.target.name == 'signagename') {
            settingService.getSingle(e.target.value)
                .then((data) => {
                    setSignage({
                        signagename: data.data.signagename,
                        _id: data.data._id,
                        floor: data.data.floor,
                        ratio: data.data.ratio,
                        ads_setting: data.data.ads_setting
                    });
                    if (data && data.data && data.data.ads_setting == 1) {
                        setChecked(true)
                    }
                    else {
                        setChecked(false)
                    }

                })
        }
    }

    useEffect(() => {
        async function run() {
            await settingService.signage_get()
                .then((data) => {
                    getSignage(data.data);
                });
        }
        run()
    }, []);


    function handleSubmit(e) {
        setSubmitted(true);
        e.preventDefault();
        if (selectSignage.signagename && selectSignage.floor && selectSignage.ratio) {
            confirmAlert({
                title: message.title,
                message: message.setting.update,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            e.preventDefault();
                            console.log(selectSignage);
                            settingService.signage_update(selectSignage, checked)
                                .then((data) => {
                                    if (data.status.code === 0) {
                                        dispatch(alertActions.success(data.status.message));
                                        setTimeout(
                                            () => (dispatch(alertActions.clear())),
                                            2000
                                        );

                                        settingService.signage_get()
                                            .then((data) => getSignage(data.data))
                                    }
                                })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => null
                    }
                ]
            });
        }
    }


    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container">
                <PageHead title="Setting" />
                <div className="setting_main flt">
                    <form className="setting_form flt">
                        <div className="setting_head flt">
                            <span>Sigange Name</span>
                            <select onChange={(e) => changeHandler(e)} name="signagename" value={selectSignage._id} className={(submitted && !selectSignage.signagename ? ' is-invalid' : '')}>
                                <option value='' selected disabled>Please select</option>
                                {data.map((option) => (
                                    <option value={option._id} key={option._id} >{option.signagename}</option>
                                ))}
                            </select>
                            {submitted && !selectSignage.signagename &&
                                <div className="invalid-feedback">Please select signage name</div>
                            }
                        </div>
                        <div className="setting_cont flt">
                            <div className="form-group flt">
                                <label>広告を受け付ける</label>
                                <Switch
                                    onChange={handleChange}
                                    checked={checked}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    value={selectSignage.ads_setting}
                                    name="ads_setting"
                                    offHandleColor="#fff"
                                    offColor="#ccc"
                                    onHandleColor="#000"
                                    onColor="#ccc"
                                    border="1px solid #000"
                                    className="react-switch"
                                />
                            </div>
                            <div className="form-group flt">
                                <label>サイネージ設置場所</label>
                                <input type="text" name="floor" value={selectSignage.floor} onChange={changeHandler} className={(submitted && !selectSignage.floor ? ' is-invalid' : '')} />
                                {submitted && !selectSignage.floor &&
                                    <div className="invalid-feedback">Signage floor is required</div>
                                }
                            </div>
                            <div className="form-group flt">
                                <label>サイネージのサイズ設定</label>
                                <select onChange={(e) => changeHandler(e)} name="ratio" value={selectSignage.ratio} className={(submitted && !selectSignage.ratio ? ' is-invalid' : '')}>
                                    <option value='' selected disabled>Please select</option>
                                    <option>16:9</option>
                                    <option>9:16</option>
                                </select>
                                {submitted && !selectSignage.ratio &&
                                    <div className="invalid-feedback">Signage ratio is required</div>
                                }
                            </div>
                            <div className="setting_btn flt">
                                <button onClick={(e) => handleSubmit(e)}>SAVE</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Setting
