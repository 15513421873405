import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import profile_edit from '../../../assets/images/profile_edit.png'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { profileService } from '../../../_services';
import { alertActions } from '../../../_actions/alert.actions'
import { useDispatch } from 'react-redux';
import message from '../../_common/Message/message';
import profile_image from '../../../assets/images/profile_image.png';


function Profile() {
    const dispatch = useDispatch();
    const profile_url = process.env.REACT_APP_MEDIA_DOMAIN; // profile image url
    const [data, setData] = useState([]);
    const [numberValid, setNumberValid] = useState(true);
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [validFile, setValidFile] = useState(true); // file type must be image only

    useEffect(() => {
        profileService.getProfile()
            .then((data) => {
                if (data.status.code === 0) {
                    setData({
                        firstName: data.data && data.data.firstName ? data.data.firstName : '',
                        company_name: data.data && data.data.company_name ? data.data.company_name : '',
                        telephone_number: data.data && data.data.telephone_number ? data.data.telephone_number : '',
                    });
                    localStorage.setItem('user_name', data.data.firstName);
                    localStorage.setItem('profile', data.data.profile);
                    setImagePreviewUrl(data.data && data.data.profile ? profile_url + data.data.profile : '')
                }
            }
            )
    }, []);

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setData(data => ({ ...data, [name]: value }))

        if (e.target.name == 'telephone_number') {
            var filter = /^[0-9]*$/;
            if (!filter.test(e.target.value)) {
                setNumberValid(false)
            } else {
                setNumberValid(true)
            }
        } // number validation
    }

    const changeFileHandler = (e) => {
        setSelectedFile(e.target.files[0]);

        setImagePreviewUrl(null)

        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file.type.match('image.*')) {
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
                setValidFile(true)
            }
        }
        else {
            setValidFile(false)
        }
        reader.readAsDataURL(file);
    };

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if (data.firstName && data.company_name && data.telephone_number && numberValid && validFile) {
            confirmAlert({
                title: message.title,
                message: message.profile.update,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            e.preventDefault();
                            profileService.updateProfile(data, selectedFile)
                                .then((data) => {
                                    if (data.status.code === 0) {
                                        profileService.getProfile()
                                            .then((data) => setData(data))
                                        dispatch(alertActions.success(data.status.message));
                                        setTimeout(
                                            () => (dispatch(alertActions.clear())),
                                            2000
                                        );
                                    }
                                })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => null
                    }
                ]
            });
            setSubmitted(false);
        }
    }
    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container">
                <PageHead title="Profile" />
                <div className="profile_main flt">
                    <div className="profile_left">
                        <ul>
                            <li className="active"><Link to="profile"><i className="fas fa-pencil-alt"></i><span>Edit Profile</span></Link></li>
                            <li><Link to="password-security"><i className="fas fa-shield-alt"></i><span>Password & Secutiry</span></Link></li>
                        </ul>
                    </div>
                    <div className="profile_right">
                        <div className="profile_head flt">
                            Edit Profile
                        </div>
                        <div className="profile_cont flt">
                            <div className="profile_image flt">
                                <span>
                                    {imagePreviewUrl &&
                                        <img src={imagePreviewUrl} className="myprofile" alt="profile_image" />
                                    }{!imagePreviewUrl &&
                                        <img src={profile_image} className="myprofile" alt="profile_image" />
                                    }
                                    <div className="profile_upload">
                                        <img src={profile_edit} alt="profile_edit" />
                                        <input type="file" accept="image/*" name="profile" onChange={changeFileHandler} />
                                    </div>
                                </span>
                                {!validFile &&
                                    <div className="invalid-feedback profile_invalid">File type must be image only</div>
                                }
                            </div>

                            <form className="profile_form flt">
                                <div className="form-group flt">
                                    <label>「更新担当者名」</label>
                                    <input type="text" name="firstName" value={data.firstName} onChange={changeHandler} />
                                    {submitted && !data.firstName &&
                                        <div className="invalid-feedback">Name is required</div>
                                    }
                                </div>
                                <div className="form-group flt">
                                    <label>会社名</label>
                                    <input type="text" name="company_name" value={data.company_name} onChange={changeHandler} />
                                    {submitted && !data.company_name &&
                                        <div className="invalid-feedback">Company name is required</div>
                                    }
                                </div>
                                <div className="form-group flt">
                                    <label>電話番号</label>
                                    <input type="text" name="telephone_number" value={data.telephone_number} onChange={changeHandler} />
                                    {submitted && !data.telephone_number &&
                                        <div className="invalid-feedback">Number is required</div>
                                    }
                                    {!numberValid && data.telephone_number &&
                                        <div className="invalid-feedback">Please enter valid number</div>
                                    }
                                </div>
                                <div className="profile_btn flt">
                                    <button onClick={(e) => handleSubmit(e)}>SAVE</button>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile
