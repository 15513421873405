import { authHeader } from '../_helpers';
const axios = require('axios').default;
export const securityService = {
    add
}

async function add(add) {
    var bodyFormData = new FormData();
    bodyFormData.append("old_password",add.old_password)
    bodyFormData.append("new_password",add.new_password)
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL}merchant/update-password`,
        headers: authHeader(),
        data: bodyFormData
    };
    return await axios(
        requestOptions
    ).then(handleResponse);
}

function handleResponse(response) {
    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }

        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }

    return response.data;

}