import React, { useEffect, useState } from 'react'
import { history } from '../../../_helpers';

function PageHead(props) {
    const profile_url = process.env.REACT_APP_MEDIA_DOMAIN; // profile image url
    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };
    function logout() {
        history.push('/login');
        // remove user from local storage to log user out
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
    }
    var userName = '';
    var profile = ''


    if (localStorage.hasOwnProperty('user')) {
        userName = localStorage.getItem('user_name');
        profile = localStorage.getItem('profile');
    }

    return (
        <div className="page_head flt">
            <div className="page_title">{props.title}</div>
            <div className="filler"></div>
            <div className="profile_icon" onClick={handleToggle}>
                <img src={profile_url + profile} alt="profile" />
                <div className={isActive ? "app" : 'app2'}>
                    {/* <Link to="/login">Logout</Link> */}
                    <button onClick={logout}>Logout</button>
                </div>
            </div>
            <div className="profile_name">{userName}</div>
        </div>
    )
}

export default PageHead
