import React, {useEffect, useState} from 'react';
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import { invoiceService } from  '../../../_services';
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css"; // import css
import { Link } from 'react-router-dom';

function Invoice() {

    const [data, setData] = useState([]);
    const [current, setcurrent] = useState(1);

    useEffect(() => {
        invoiceService.invoice_get(current)
            .then((data) => setData(data))
    }, [current]);

    const changeCurrentPage = (page) => {
        setcurrent(page)
    }
    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container">
                <PageHead title="INVOICE" />
                <div className="sales_main flt">
                    <div className="sales_table flt">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th width="60px">Sl. No.</th>
                                    <th>Amount</th>
                                    <th>Remark</th>
                                    <th>Status</th>
                                    <th>Billed On</th>
                                    <th>Invoice Month</th>
                                    <th>View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.data && data.data.docs != null ? data.data.docs.map((list, index) =>
                                    <tr key={list._id}>
                                        <td>{10 * (current - 1) + index + 1}.</td>
                                        <td>{list.amount}</td>
                                        <td>{list.remark}</td>
                                        <td>{list.status === 1 ? 'Paid' : 'Unpaid'}</td>
                                        <td>{list.billed_on}</td>
                                        <td>{list.month_year}</td>
                                        <td><Link to={"/invoice-individual/" + list.billId}><i className="far fa-eye"></i></Link></td>
                                    </tr>) : null}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination flt">
                        <Pagination
                            currentPage={current}
                            totalSize={data.data && data.data.totalPages * data.data.limit}
                            showFirstLastPages={true}
                            changeCurrentPage={changeCurrentPage}
                            numberOfPagesNextToActivePage={5}
                            theme="bootstrap"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invoice
