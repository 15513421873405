import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import './assets/css/style.css';
import LandingPage from './components/pages/LandingPage/LandingPage';
import Login from './components/pages/LoginPage/Login';
import Dashboard from './components/pages/Dashboard/Dashboard';
import Profile from './components/pages/ProfilePage/Profile';
import Security from './components/pages/SecurityPage/Security';
import Setting from './components/pages/SettingPage/Setting';
import PrivacyPolicy from './components/pages/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './components/pages/TermsConditions/TermsConditions';
import Contact from './components/pages/ContactPage/Contact';
import Sales from './components/pages/SalesPage/Sales';
import { history } from './_helpers';
import { alertActions } from './_actions';
import { PrivateRoute } from './_components';
import { useDispatch, useSelector } from 'react-redux';
import Invoice from './components/pages/Invoice/Invoice';
import InvoiceIndividual from './components/pages/InvoiceIndividual/InvoiceIndividual';

 
function App() {
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();
    require('dotenv').config()
   

  return (
         <div>
            {alert.message &&
                <div className={`alert ${alert.type}`}>{alert.message}</div>
            }
            <Router history={history}>
                <Switch>
                <Route path='/dashboard' component={Dashboard} />
                <Route path='/profile' component={Profile} />
                <Route path='/password-security' component={Security} />
                <Route path='/setting' component={Setting}/>
                <Route path='/privacy-policy' component={PrivacyPolicy}/>
                <Route path='/terms-conditions' component={TermsConditions}/>
                <Route path='/contact' component={Contact} />
                <Route path='/sales' component={Sales} />
                <Route path='/invoice' component={Invoice} />
                <Route path='/invoice-individual/:invoice_id' component={InvoiceIndividual} />
                <Route path='/login' component={Login} />
                <Route path='/' component={LandingPage} />
        
                <Redirect from="*" to="/" />
                </Switch>
            </Router>
         </div>
  
     
  );
}

export default App;
