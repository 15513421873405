import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { securityService } from '../../../_services';
import { alertActions } from '../../../_actions/alert.actions'
import { useDispatch } from 'react-redux';
import message from '../../_common/Message/message';

function Security() {
    const dispatch = useDispatch();
    const [add, setAdd] = useState({
        old_password: '',
        new_password: '',
        confirm_password: ''
    });
    const [passwordValid, setPasswordValid] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [rst, setRst] = useState(false);
    const rstHandler = () => {
        setRst(!rst)
    };

    const [psw, setPsw] = useState(false);
    const pswHandler = () => {
        setPsw(!psw)
    }

    const changeHandler = (e) => {
        if ((e.target.name == 'confirm_password') || (e.target.name == 'new_password')) {
            if ((add.new_password == e.target.value)||(add.confirm_password == e.target.value)) {
                setPasswordValid(true);
            }
            else {
                setPasswordValid(false);
            }
        } // confirm password validation

        const { name, value } = e.target;
        setAdd(data => ({ ...data, [name]: value }));
    }


    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if (add.old_password && add.new_password && add.confirm_password && passwordValid) {
            confirmAlert({
                title: message.title,
                message: message.password.update,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            securityService.add(add)
                                .then((data) => {
                                    if (data.status.code === 0) {
                                        dispatch(alertActions.success(data.status.message));
                                        setTimeout(
                                            () => (dispatch(alertActions.clear())),
                                            2000
                                        );
                                        setAdd({
                                            old_password: '',
                                            new_password: '',
                                            confirm_password: ''
                                        });
                                        setSubmitted(false);
                                        setRst(false);
                                    }
                                   else {
                                        dispatch(alertActions.error(data.status.message));
                                        setTimeout(
                                            () => (dispatch(alertActions.clear())),
                                            2000
                                        );
                                    }

                                })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => null
                    }
                ]
            });
        }
    }







    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container">
                <PageHead title="Profile" />
                <div className="profile_main flt">
                    <div className="profile_left">
                        <ul>
                            <li><Link to="profile"><i className="fas fa-pencil-alt"></i><span>Edit Profile</span></Link></li>
                            <li className="active"><Link to="password-security"><i className="fas fa-shield-alt"></i><span>Password & Secutiry</span></Link></li>
                        </ul>
                    </div>
                    <div className="profile_right">
                        <div className="profile_head flt">
                            Password & Security
                        </div>
                        <div className="profile_desc flt">
                            <div className="security_box flt">
                                <div className="security_head flt">
                                    <div className="security_title">パスワード</div>
                                    <div className="filler"></div>
                                    <div className="security_icon" onClick={rstHandler}><i className="far fa-edit"></i></div>
                                </div>
                                {rst ? <div className="security_cont flt">
                                    <div className="security_name flt">Reset Password</div>
                                    <form className="security_form flt">
                                        <div className="form-group flt">
                                            <label>Current Password</label>
                                            <input type="password" name="old_password" value={add.old_password} onChange={changeHandler} />
                                            {submitted && !add.old_password &&
                                                <div className="invalid-feedback">
                                                    Current password is requird
                                                </div>
                                            }
                                        </div>
                                        <div className="form-group flt">
                                            <label>New Password</label>
                                            <input type="password" name="new_password" value={add.new_password} onChange={changeHandler} />
                                            {submitted && !add.new_password && 
                                                <div className="invalid-feedback">
                                                    New password is requird
                                                </div>
                                            }
                                        </div>
                                        <div className="form-group flt">
                                            <label>Confirm New Password</label>
                                            <input type="password" name="confirm_password" value={add.confirm_password} onChange={changeHandler} />
                                            {submitted && !add.confirm_password &&
                                                <div className="invalid-feedback">
                                                    Confirm password is requird
                                                </div>
                                            }
                                            {add.confirm_password && !passwordValid &&
                                                <div className="invalid-feedback">
                                                    Password did not match
                                                </div>
                                            }
                                        </div>
                                        <div className="security_btn flt">
                                            <button onClick={(e) => handleSubmit(e)}>
                                                SAVE
                                            </button>
                                        </div>

                                    </form>
                                </div> : ''}
                                {!rst ? <div className="psw flt">
                                    <div className="psw_icon"><i className="fas fa-check-circle"></i></div>
                                    <div className="psw_cont"><span>Password has been set</span><br />Choose a strong, unique password that’s atleast 8 characters long.</div>
                                </div> : ''}
                            </div>
                            {/* <div className="security_box flt">
                                <div className="security_head flt">
                                    <div className="security_title">メールの確認<br /><span>Help protect your account by enabling extra layers of security.</span></div>
                                    <div className="filler"></div>
                                    <div className="security_icon" onClick={pswHandler}><i className="far fa-edit"></i></div>
                                </div>
                                {psw ? <div className="security_cont flt">
                                    <form className="confirmation_form flt">
                                        <div className="form-group flt">
                                            <label>メールID</label>
                                            <input type="text" />
                                        </div>
                                        <div className="form-group flt">
                                            <label>メールIDを確認する</label>
                                            <input type="text" />
                                        </div>
                                        <div className="security_btn flt">
                                            <button>
                                                SAVE
                                            </button>
                                        </div>

                                    </form>
                                </div>
                                    : ''}
                            </div> */}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Security
