import React, {useEffect, useState} from 'react';
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import { invoiceindividualService } from '../../../_services';
import { useParams } from 'react-router-dom';
import sales_price from '../../../assets/images/sales_price.png'



function InvoiceIndividual() {

    const params = useParams();
    let id = params.invoice_id;

    const [data, setData] = useState([]);
    useEffect(() => {
        invoiceindividualService.invoice_get(id)
            .then((data) => setData(data))
    }, []);


    return (
        <div className="main_container flt">
            <Sidebar />
            <div className="page_container">
                <PageHead title="INVOICE INDIVIDUAL" />
                <div className="sales_main flt">
                    <div className="sales_table flt">
                    <table className="table">
                            <thead>
                                <tr>
                                    <th width="60px">Sl. No</th>
                                    <th>Reference</th>
                                    <th>Description</th>
                                    <th>Date of Add</th>
                                    <th>Date of Expiry</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.data && data.data.sales != null ? data.data.sales.map((list, index) =>
                                    <tr key={list._id}>
                                        <td>{index + 1}.</td>
                                        <td>{list.referenceId}</td>
                                        <td>{list.ads_info}</td>
                                        <td>{list.added_date}</td>
                                        <td>{list.expiry_date}</td>
                                        <td>{list.amount}</td>
                                    </tr>) : null}
                            </tbody>
                        </table>
                    </div>
                    <div className="sales_total flt">
                        <div className="total_box">
                            <div className="price_txt">
                                <div className="price_tag flt">TOTAL PRICE :</div>
                                <div className="price_rate flt">¥ {data.data && data.data.amount}</div>
                            </div>
                            <div className="filler"></div>
                            <div className="price_icon">
                                <img src={sales_price} alt="white-rice" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceIndividual



