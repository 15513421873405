import { authHeader } from '../_helpers';
const axios = require('axios').default;
export const appsetting = {
    getcontent
}

async function getcontent(type) {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_URL}/merchant/static-page?type=${type}`,
        headers: authHeader()
    };

    //return fetch(`${process.env.REACT_APP_BASE_URL}/merchant/static-page?type=${type}`, requestOptions).then(handleResponse);
    //return axios(`${process.env.REACT_APP_BASE_URL}/merchant/static-page?type=${type}`, requestOptions).then(handleResponse);
    return await axios(
        requestOptions
    ).then(handleResponse);
}
function handleResponse(response) {
    console.log(response)


    if (!response.statusText) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api

            //window.location.reload(true);
        }

        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }

    return response.data;

}