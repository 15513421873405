import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import message from '../../_common/Message/message';
import { alertActions } from '../../../_actions/alert.actions';
import { dashboard } from '../../../_services/dashboard';
import ModernDatepicker from 'react-modern-datepicker';
import PageHead from '../../_common/PageHead/PageHead';
import Sidebar from '../../_common/Sidebar/Sidebar';
import attach from '../../../assets/images/attach.png'
import image_icon from '../../../assets/images/image_icon.png'
import add_file from '../../../assets/images/add_file.png';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Loader from "react-loader-spinner";

function Dashboard() {

    const media_url = process.env.REACT_APP_MEDIA_DOMAIN; // media domain
    const [data, getSignage] = useState([{ name: '', _id: '' }]);
    const [selectSignage, setSignage] = useState({ _id: '', signagename: '', ratio: '' });
    const [adds, setAds] = useState([{ _id: '', from: '', ads_info: '', amount: '' }]);
    const [signage_content, setSignageContent] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [videoPreviewUrl, setVideoPreviewUrl] = useState();
    const [uploader, setUploader] = useState({
        title: '',
        representation_time: '',
        loop_playback: '',
        publication_date_and_fromtime: '',
        publication_date_and_totime: ''
    });
    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [loader, setLoader] = useState(false);

    function fromChangeHandler(e) {
        uploader.publication_date_and_fromtime = e;
        setFromDate(uploader.publication_date_and_fromtime);//state used to set the from date
    }
    function toChangeHandler(e) {
        uploader.publication_date_and_totime = e;
        setToDate(uploader.publication_date_and_totime);//state used to set the to date
    }

    const [advDelete, setAdvDelete] = useState(false); // advertisement delete button toggle
    const [advId, setAdvId] = useState();
    function advOpen(_id) {
        setAdvId(_id)
        setAdvDelete(!advDelete)
    }

    const [more, setMore] = useState(false); //more toggle button
    const [sinageId, setSinageId] = useState(false);
    function moreOpen(_id) {
        setSinageId(_id)
        setMore(!more)
    }

    const [editOpen, setEditOpen] = useState(false);
    const [editId, setEditId] = useState('');
    const [isImageUrl, setIsImageUrl] = useState(false);
    const [numberValid, setNumberValid] = useState(true);
    const [playBack, setPlayBack] = useState(true);
    const [validFile, setValidFile] = useState(true); // file type must be image only


    function editCall(_id, index) {
        setEditId(_id)
        setEditOpen(true)
        setUploader({
            _id: signage_content[index]._id,
            title: signage_content[index].title,
            representation_time: signage_content[index].representation_time,
            loop_playback: signage_content[index].loop_playback,
            publication_date_and_fromtime: signage_content[index].publication_date_and_fromtime,
            publication_date_and_totime: signage_content[index].publication_date_and_totime,
        });

        var url = media_url + signage_content[index].upload_file
        console.log(url)
        if (url.match(/\.(jpeg|jpg|gif|png)$/) != null) {
            setIsImageUrl(true);
            setImagePreviewUrl(media_url + signage_content[index].upload_file);
        } else {
            setIsImageUrl(false);
            setVideoPreviewUrl(media_url + signage_content[index].upload_file);
        }
    }

    function cancelCall(_id) {
        setEditId(_id);
        setEditOpen(false);
        setMore(false);
        setSelectedFile(false);
        setUploader({
            title: '',
            representation_time: '',
            loop_playback: '',
            publication_date_and_fromtime: '',
            publication_date_and_totime: ''
        });
    }


    function handleChange(e) {
        if (e.target.name == 'representation_time') {
            var filter = /^[0-9]*$/;
            if (!filter.test(e.target.value)) {
                setNumberValid(false)
            } else {
                setNumberValid(true)
            }
        } // number validation for representation time
        if (e.target.name == 'loop_playback') {
            var filter = /^[0-9]*$/;
            if (!filter.test(e.target.value)) {
                setPlayBack(false)
            } else {
                setPlayBack(true)
            }
        } // number validation for playback loop


        const { name, value } = e.target;
        setUploader(uploader => ({ ...uploader, [name]: value }));

    }


    function handleSignageChange(e) {
        if (e.target.name == 'signagename') {
            signageContent(e.target.value);
            signageAdds(e.target.value);
            dashboard.getSingle(e.target.value)
                .then((data) => {
                    setSignage({
                        signagename: data.data.signagename,
                        _id: data.data._id,
                        ratio: data.data.ratio
                    });
                })
        }
    }


    useEffect(() => {
        async function run() {
            // setLoader(true);
            await dashboard.signage_get()
                .then((dat) => {
                    // setLoader(false);
                    getSignage(dat.data);
                    if (dat.data.length > 0) {

                        setSignage({ _id: dat.data[0]._id, signagename: dat.data[0].signagename, ratio: dat.data[0].ratio });
                        signageContent(dat.data[0]._id);
                        signageAdds(dat.data[0]._id);
                    }

                });


        }
        run()


    }, [])
    //console.log(signage_content);
    function signageAdds(id) {
        dashboard.ads_get(id)
            .then((dat) => {
                setAds(dat.data.advertisements);
            });
    }
    function signageContent(id) {
        dashboard.signage_content(id)
            .then((dat) => {
                setSignageContent(dat.data.signage_content);

            });

    }
    function handleOnDragEnd(result) {
        console.log(result);
        if (!result.destination) return;
        const items = Array.from(signage_content);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        // signage_content=items;
        setSignageContent(items);
        dashboard.updateOrder(items, selectSignage._id)
            .then((data) => {
                if (data.status.code === 0) { }
            })
    }

    function handleOnDragEndAdds(id) {
        console.log(id);

        confirmAlert({
            title: message.title,
            message: message.dashboard.add,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => dashboard.drag(id, selectSignage._id)
                        .then((data) => {
                            if (data.status.code === 0) {
                                dashboard.signage_content(selectSignage._id)
                                    .then((dat) => {
                                        setSignageContent(dat.data.signage_content)
                                    });
                                dashboard.ads_get(selectSignage._id)
                                    .then((dat) => {
                                        setAds(dat.data.advertisements)
                                    });

                                dispatch(alertActions.success(data.status.message));
                                setTimeout(
                                    () => (dispatch(alertActions.clear())),
                                    2000
                                );

                            }
                        })
                },
                {
                    label: 'No',
                    onClick: () => null
                }
            ]
        });
    }

    const changeFileHandler = (e) => {
        setSelectedFile(e.target.files[0]);
        setImagePreviewUrl(null)
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file.type.match('image.*')) {
            setIsImageUrl(true);
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result)
            }
            setValidFile(true)
        } else if (file.type.match('video.*')) {
            setIsImageUrl(false);
            console.log(URL.createObjectURL(file))
            setVideoPreviewUrl(URL.createObjectURL(file))
            setValidFile(true)
        }
        else {
            setValidFile(false)
        }
        reader.readAsDataURL(file);

    }


    const cancelHandler = () => {
        setImagePreviewUrl(null);
        setSelectedFile(null);
        setVideoPreviewUrl(null);
        setUploader({
            title: '',
            representation_time: '',
            loop_playback: '',
            publication_date_and_fromtime: '',
            publication_date_and_totime: ''
        })

    }



    const submitHandler = (e) => {
        e.preventDefault();
        setSubmitted(true);

        if (imagePreviewUrl && !uploader.representation_time) {
            return false
        }
        if (imagePreviewUrl && !numberValid) {
            return false
        }

        if (uploader.title && uploader.loop_playback && uploader.publication_date_and_fromtime && uploader.publication_date_and_totime && selectedFile && playBack && validFile) {

            confirmAlert({
                title: message.title,
                message: message.dashboard.add,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {

                            uploader.signageId = selectSignage._id;
                            dashboard.uploader(uploader, selectedFile)
                                .then((data) => {
                                    if (data.status.code === 0) {
                                        setUploader({
                                            title: '',
                                            representation_time: '',
                                            loop_playback: '',
                                            publication_date_and_fromtime: '',
                                            publication_date_and_totime: ''
                                        });
                                        setSubmitted(false);
                                        setImagePreviewUrl(null);
                                        setSelectedFile(null);
                                        setVideoPreviewUrl(null);
                                        dashboard.signage_content(selectSignage._id)
                                            .then((dat) => {
                                                setSignageContent(dat.data.signage_content)

                                            });
                                        dispatch(alertActions.success(data.status.message));
                                        setTimeout(
                                            () => (dispatch(alertActions.clear())),
                                            2000
                                        );

                                    }
                                })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => null

                    }
                ]
            });

        }
    }


    const submitEdit = (e) => {
        setSubmitted(true);
        e.preventDefault();

        if (imagePreviewUrl && !uploader.representation_time) {
            return false
        }
        if (imagePreviewUrl && !numberValid) {
            return false
        }

        if (uploader.title && uploader.loop_playback && uploader.publication_date_and_fromtime && uploader.publication_date_and_totime && playBack && validFile) {
            confirmAlert({
                title: message.title,
                message: message.dashboard.update,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {

                            uploader.signageId = selectSignage._id;
                            dashboard.signage_edit(uploader, selectedFile)
                                .then((data) => {
                                    if (data.status.code === 0) {
                                        setUploader({
                                            id: '',
                                            title: '',
                                            representation_time: '',
                                            loop_playback: '',
                                            publication_date_and_fromtime: '',
                                            publication_date_and_totime: ''
                                        })

                                        setSelectedFile(false)
                                        setImagePreviewUrl(null);
                                        setVideoPreviewUrl(null);
                                        setEditId(null)
                                        setEditOpen(false)
                                        setMore(false)
                                        setSubmitted(false);
                                        dashboard.signage_content(selectSignage._id)
                                            .then((dat) => {
                                                setSignageContent(dat.data.signage_content)

                                            });
                                        dispatch(alertActions.success(data.status.message));
                                        setTimeout(
                                            () => (dispatch(alertActions.clear())),
                                            2000
                                        );
                                    }
                                })
                        }
                    },

                    {
                        label: 'No',
                        onClick: () => null

                    }
                ]
            });
        }
    }



    const handleDelete = (id) => {
        console.log(id)
        confirmAlert({
            title: message.title,
            message: message.dashboard.delete,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => dashboard.delete(id)
                        .then((data) => {
                            if (data.status.code === 0) {
                                dashboard.signage_content(selectSignage._id)
                                    .then((dat) => {
                                        setSignageContent(dat.data.signage_content)

                                    });
                                dashboard.ads_get(selectSignage._id)
                                    .then((dat) => {
                                        setAds(dat.data.advertisements)
                                    });
                                dispatch(alertActions.success(data.status.message));
                                setTimeout(
                                    () => (dispatch(alertActions.clear())),
                                    2000
                                );

                            }
                        })
                },
                {
                    label: 'No',
                    onClick: () => null
                }
            ]
        });
    }


    // specify upload params and url for your files
    const getUploadParams = ({ meta }) => { return { url: '/' } }

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    }

    return (

        <div className="main_container flt">
            {/* {loader && <Loader
                type="Oval"
                color="rgb(40 44 52)"
                height={100}
                width={100}
                className="loader"
            />} */}
            <Sidebar />
            <div className="page_container">
                <PageHead title="Dashboard" />
                <div className="page_content flt">
                    <div className="dashboard_drops flt">
                        <div className="drops_left">
                            <div className="drops_name flt">
                                Signage :
                            </div>
                            <div className="drops_select flt">
                                <select name="signagename" value={selectSignage._id} onChange={handleSignageChange}>
                                    {data.map((option) => (
                                        <option value={option._id}>{option.signagename}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="filler"></div>
                        <div className="drops_right">
                            <div className="drops_name flt">
                                Screen Resolutions :
                            </div>
                            <div className="drops_select flt">
                                {selectSignage.ratio}
                            </div>
                        </div>
                    </div>
                    <div className="dashboard_cont flt">
                        <div className="dashboard_left">
                            <div className="dashboard_left_head flt">JACK SIGNAGE CONTENTS:</div>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="characters">
                                    {(provided) => (
                                        <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                            {signage_content.map(({ _id, title, content_type, representation_time, publication_date_and_fromtime, publication_date_and_totime, loop_playback, upload_file, amount, type }, index) => {
                                                return (
                                                    <Draggable key={_id} draggableId={_id} index={index}>
                                                        {(provided) => (

                                                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

                                                                {_id != editId && type == 0 &&

                                                                    <div className="dash_box flt" id={_id}>
                                                                        <div className="dash_more flt"><i className="fas fa-ellipsis-h" id={_id} onClick={() => moreOpen(_id)}></i>
                                                                            {more && _id == sinageId &&
                                                                                <div className="dash_pop">
                                                                                    <ul>
                                                                                        <li><button type="button" onClick={() => editCall(_id, index)}>Edit</button></li>
                                                                                        <li><button type="button" onClick={() => handleDelete(_id)}>Delete</button></li>
                                                                                    </ul>
                                                                                </div>}
                                                                        </div>
                                                                        <div className="dash_cont flt">
                                                                            <div className="dash_txt">
                                                                                <div className="dash_name flt"> {title}</div>
                                                                                <div className="dashin flt">
                                                                                    <div className="dash_upload">
                                                                                        <div className="dash_file flt">
                                                                                            <span className="upload-btn-wrapper">
                                                                                                <input type="file" name="myfile" />
                                                                                                <img src={attach} alt="white-rice" />
                                                                                            </span>
                                                                                            <span className="upload-btn-wrapper">
                                                                                                <input type="file" name="myfile" />
                                                                                                <img src={image_icon} alt="white-rice" />
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="dash_table">
                                                                                        <table>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td width="250px">コンテンツ種類</td>
                                                                                                    <td>{content_type}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>表示時間 [ms]</td>
                                                                                                    <td>{representation_time}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>ループ再生</td>
                                                                                                    <td>{loop_playback}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>公開日時</td>
                                                                                                    <td>{publication_date_and_fromtime}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>公開日時</td>
                                                                                                    <td>{publication_date_and_totime}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dash_image">
                                                                                {(content_type == 'PNG' || content_type == 'JPG' || content_type == 'JPEG') &&
                                                                                    <img src={media_url + upload_file} alt="white-rice" />
                                                                                }
                                                                                {(content_type == 'MP4' || content_type == 'WEBM' || content_type == 'AVI') &&
                                                                                    <video width="100%" controls>
                                                                                        <source src={media_url + upload_file} type="video/mp4" />
                                                                                    </video>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    type == 1 &&
                                                                    <div className="advertise_selected">
                                                                        <div className="advertise_box">
                                                                            <button className="advertise_more" onClick={() => advOpen(_id)} id={_id}><i className="fas fa-ellipsis-h"></i></button>
                                                                            {advDelete && _id == advId &&
                                                                                <button className="advertise_delete" onClick={() => handleDelete(_id)}>Delete</button>
                                                                            }
                                                                            <div className="advertise_date"><p>{publication_date_and_fromtime}</p>{publication_date_and_totime}</div>
                                                                            <div className="advertise_comp"><p>{title}</p>{amount}</div>
                                                                            <div className="advertise_file">
                                                                                {(content_type == 'PNG' || content_type == 'JPG' || content_type == 'JPEG') &&
                                                                                    <img src={media_url + upload_file} alt="white-rice" />
                                                                                }
                                                                                {(content_type == 'MP4' || content_type == 'WEBM' || content_type == 'AVI') &&
                                                                                    <video width="100%" controls>
                                                                                        <source src={media_url + upload_file} type="video/mp4" />
                                                                                    </video>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                {/* Edit signage */}
                                                                {editOpen && _id == editId &&
                                                                    <div className="dash_box flt">
                                                                        <div className="dash_cont flt">
                                                                            <div className="dash_txt">
                                                                                <div className="dashin flt">
                                                                                    <div className="dash_table">
                                                                                        <form>
                                                                                            <table>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td width="220px">題名</td>
                                                                                                        <td>
                                                                                                            <input type="text" name="title" value={uploader.title} onChange={handleChange} className={(submitted && !uploader.title ? ' is-invalid' : '')} />
                                                                                                            {submitted && !uploader.title &&
                                                                                                                <div className="invalid-feedback">Title is required</div>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    {imagePreviewUrl &&
                                                                                                        <tr>
                                                                                                            <td width="220px">表示時間 [ms]</td>
                                                                                                            <td>
                                                                                                                <input type="text" name="representation_time" value={uploader.representation_time} onChange={handleChange} className={(submitted && !uploader.representation_time ? ' is-invalid' : '')} />
                                                                                                                {submitted && !uploader.representation_time &&
                                                                                                                    <div className="invalid-feedback">Representation time is required</div>
                                                                                                                }
                                                                                                                {uploader.representation_time && !numberValid &&
                                                                                                                    <div className="invalid-feedback">Please enter valid representation time</div>
                                                                                                                }
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    }
                                                                                                    <tr>
                                                                                                        <td width="220px">ループ再生</td>
                                                                                                        <td>
                                                                                                            <input type="text" name="loop_playback" value={uploader.loop_playback} onChange={handleChange} className={(submitted && !uploader.loop_playback ? ' is-invalid' : '')} />
                                                                                                            {submitted && !uploader.loop_playback &&
                                                                                                                <div className="invalid-feedback">Loop playback is required</div>
                                                                                                            }
                                                                                                            {uploader.loop_playback && !playBack &&
                                                                                                                <div className="invalid-feedback">Please enter valid loop playback</div>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td width="220px">公開日時</td>
                                                                                                        <td>
                                                                                                            <ModernDatepicker
                                                                                                                date={uploader.publication_date_and_fromtime && uploader.publication_date_and_fromtime}
                                                                                                                format={'YYYY-MM-DD'}
                                                                                                                icon={false}
                                                                                                                onChange={(e) => fromChangeHandler(e)}
                                                                                                                placeholder={'Select From date'}
                                                                                                                className={(submitted && !uploader.publication_date_and_fromtime ? ' is-invalid' : '')}
                                                                                                            />
                                                                                                            {submitted && !uploader.publication_date_and_fromtime &&
                                                                                                                <div className="invalid-feedback">Publication date is required</div>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td width="220px">有効期限</td>
                                                                                                        <td>
                                                                                                            <ModernDatepicker
                                                                                                                date={uploader.publication_date_and_totime && uploader.publication_date_and_totime}
                                                                                                                format={'YYYY-MM-DD'}
                                                                                                                icon={false}
                                                                                                                onChange={(e) => toChangeHandler(e)}
                                                                                                                placeholder={'Select To date'}
                                                                                                                minDate={uploader.publication_date_and_fromtime && uploader.publication_date_and_fromtime}
                                                                                                                className={(submitted && !uploader.publication_date_and_totime ? ' is-invalid' : '')}
                                                                                                            />
                                                                                                            {submitted && !uploader.publication_date_and_totime &&
                                                                                                                <div className="invalid-feedback">Expiry date is required</div>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dash_image">
                                                                                {!validFile &&
                                                                                    <div className="invalid-feedback profile_invalid">File type must be image only</div>
                                                                                }
                                                                                <div className="image">
                                                                                    {isImageUrl &&
                                                                                        <img src={imagePreviewUrl} alt="white-rice" />
                                                                                    }
                                                                                </div>
                                                                                <div className="video">
                                                                                    {!isImageUrl &&
                                                                                        <video width="100%" controls>
                                                                                            <source src={videoPreviewUrl} type="video/mp4" />
                                                                                            Your browser does not support HTML5 video.
                                                                                        </video>
                                                                                    }
                                                                                </div>
                                                                                <div className="edit_upload flt">
                                                                                    <div className="edit_txt flt">
                                                                                        <img src={add_file} />Change file
                                                                                    </div>
                                                                                    <input type="file" accept="image/*, video/*" onChange={changeFileHandler} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="dash_submit flt">
                                                                            <button className="save" onClick={submitEdit}>アップロード</button>
                                                                            <button className="cancel" onClick={cancelCall}>キャンセル</button>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </li>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>

                            {/* <div className="dash_btn flt">
                                <button className="save">保存</button>
                                <button className="cancel">プレビュー</button>
                            </div> */}
                            {!editOpen && <div className="drag_main flt">
                                <div className="drag_name flt">FILE UPLOADER :</div>
                                <div className="drag_tag flt">ここにファイルをドラッグ&ドロップしてください.</div>
                                <div className="drag_cont flt">
                                    {!selectedFile &&
                                        <div className="drag_box flt">
                                            <div className="upload_btn_wrapper flt">
                                                <div className="upload_txt flt">
                                                    <img src={add_file} /><span>Add file</span> Or Drop files here
                                                </div>
                                                <input type="file" accept="image/*, video/*" onChange={changeFileHandler} />
                                            </div>
                                        </div>
                                    }
                                    {selectedFile &&
                                        <div className="dash_box flt">
                                            <div className="dash_cont flt">
                                                <div className="dash_txt">
                                                    <div className="dashin flt">
                                                        <div className="dash_table">
                                                            <form>
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="220px">題名</td>
                                                                            <td>
                                                                                <input type="text" name="title" value={uploader.title} onChange={handleChange} className={(submitted && !uploader.title ? ' is-invalid' : '')} />
                                                                                {submitted && !uploader.title &&
                                                                                    <div className="invalid-feedback">Title is required</div>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        {selectedFile && imagePreviewUrl &&
                                                                            <tr>
                                                                                <td width="220px">表示時間 [ms]</td>
                                                                                <td>
                                                                                    <input type="text" name="representation_time" value={uploader.representation_time} onChange={handleChange} className={(submitted && !uploader.representation_time ? ' is-invalid' : '')} />
                                                                                    {submitted && !uploader.representation_time &&
                                                                                        <div className="invalid-feedback">Representation time is required</div>
                                                                                    }
                                                                                    {uploader.representation_time && !numberValid &&
                                                                                        <div className="invalid-feedback">Please enter valid representation time</div>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                        <tr>
                                                                            <td width="220px">ループ再生</td>
                                                                            <td>
                                                                                <input type="text" name="loop_playback" value={uploader.loop_playback} onChange={handleChange} className={(submitted && !uploader.loop_playback ? ' is-invalid' : '')} />
                                                                                {submitted && !uploader.loop_playback &&
                                                                                    <div className="invalid-feedback">Loop playback is required</div>
                                                                                }
                                                                                {uploader.loop_playback && !playBack &&
                                                                                    <div className="invalid-feedback">Please enter valid loop playback</div>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="220px">公開日時</td>
                                                                            <td>
                                                                                <ModernDatepicker
                                                                                    date={uploader.publication_date_and_fromtime && uploader.publication_date_and_fromtime}
                                                                                    format={'YYYY-MM-DD'}
                                                                                    icon={false}
                                                                                    onChange={(e) => fromChangeHandler(e)}
                                                                                    placeholder={'Select From date'}
                                                                                    className={(submitted && !uploader.publication_date_and_fromtime ? ' is-invalid' : '')}
                                                                                />
                                                                                {submitted && !uploader.publication_date_and_fromtime &&
                                                                                    <div className="invalid-feedback">Publication date is required</div>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="220px">有効期限</td>
                                                                            <td>
                                                                                <ModernDatepicker
                                                                                    date={uploader.publication_date_and_totime && uploader.publication_date_and_totime}
                                                                                    format={'YYYY-MM-DD'}
                                                                                    icon={false}
                                                                                    onChange={(e) => toChangeHandler(e)}
                                                                                    placeholder={'Select To date'}
                                                                                    minDate={uploader.publication_date_and_fromtime && uploader.publication_date_and_fromtime}
                                                                                    className={(submitted && !uploader.publication_date_and_totime ? ' is-invalid' : '')}
                                                                                />
                                                                                {submitted && !uploader.publication_date_and_totime &&
                                                                                    <div className="invalid-feedback">Expiry date is required</div>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dash_image">
                                                    {!validFile &&
                                                        <div className="invalid-feedback profile_invalid">File type must be image only</div>
                                                    }
                                                    <div className="image">
                                                        {selectedFile && imagePreviewUrl &&
                                                            <img src={imagePreviewUrl} alt="white-rice" />
                                                        }
                                                    </div>
                                                    <div className="video">
                                                        {selectedFile && videoPreviewUrl &&
                                                            <video width="100%" controls>
                                                                <source src={videoPreviewUrl} type="video/mp4" />
                                                                Your browser does not support HTML5 video.
                                                            </video>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dash_submit flt">
                                                <button className="save" onClick={submitHandler}>アップロード</button>
                                                <button className="cancel" onClick={cancelHandler}>キャンセル</button>
                                            </div>
                                        </div>
                                    }
                                    {/* <Dropzone
                                        getUploadParams={getUploadParams}
                                        onChangeStatus={handleChangeStatus}
                                        onSubmit={handleSubmit}
                                        inputContent={<div className="drop_border"><img src={add_file} alt="white-rice" /><span>Add file</span> Or Drop files here </div>}
                                        accept="image/*,audio/*,video/*"
                                    /> */}
                                </div>
                            </div>}
                        </div>
                        {/* <div className="dashboard_right">
                            <div className="dashboard_right_head flt">広告 :</div>
                            <div className="advertise_cont flt">
                                <ul className="characters" >
                                    {adds ? adds.map((list, index) =>
                                        <li >
                                            <div className="advertise_box">
                                                <div className="advertise_date"> {list.from}</div>
                                                <div className="advertise_comp"><p>{list.ads_info} </p> {list.amount}</div>
                                            </div>
                                        </li>
                                    ) : null
                                    }
                                </ul>
                            </div>
                        </div> */}
                        <div className="dashboard_right">
                            <div className="dashboard_right_head flt">広告 :</div>
                            <div className="advertise_cont flt">

                                <DragDropContext onDragEnd={handleOnDragEndAdds}>
                                    <Droppable droppableId="characters1">
                                        {(provided) => (
                                            <ul className="characters1" {...provided.droppableProps} ref={provided.innerRef}>
                                                {adds.map(({ _id, from, to, ads_info, amount, content_type, upload_file }, index) => {
                                                    return (
                                                        <Draggable key={_id} draggableId={_id} index={index}>
                                                            {(provided) => (
                                                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className="advertise_box">
                                                                        <div className="advertise_date"><p>{from}</p>{to}</div>
                                                                        <div className="advertise_comp"><p>{ads_info}</p>{amount}</div>
                                                                        <div className="advertise_media">
                                                                            {(content_type == 'PNG' || content_type == 'JPG' || content_type == 'JPEG') &&
                                                                                <img src={media_url + upload_file} alt="white-rice" />
                                                                            }
                                                                            {(content_type == 'MP4' || content_type == 'WEBM' || content_type == 'AVI') &&
                                                                                <video width="100%" controls>
                                                                                    <source src={media_url + upload_file} type="video/mp4" />
                                                                                </video>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </ul>
                                        )}
                                    </Droppable>
                                </DragDropContext>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
